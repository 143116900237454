<template>
    <div class="wrapper_main" :class="{ 'processing':loading }">
        <main class="content" v-if="!loading">
            <div class="content__header">
                <div class="content__header-left">
                    <router-link :to="`/reports/${report.category}`" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)"/>
                    </router-link>
                    <div class="flex items-center">
                        <span class="content__header-icon"><inline-svg :src="require(`@/assets/img/ico-file-chart.svg`)"/></span>
                        <h1 class="heading-page-h1">{{ pageTitle }}</h1>
                    </div>
                </div>
            </div>
            <div class="reports">
                <div class="wizard-item">
                    <span class="datetime-title">{{ report.datetime }}</span>
                    <p>{{ report.treatment }}</p>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { reportCategoriesList } from '@/config/farm';

export default {
    name: "ReportsInner",
    data() {
        return {
            loading: true,
            reportCategoriesList: reportCategoriesList,
        };
    },
    computed:{
        report(){
            return this.$store.state.farmReports.find(item => item.id === this.$route.params.id);
        },
        pageTitle(){
            return reportCategoriesList.find(item => item.category === this.report.category).title;
        }
    },
    methods: {
        toPrevRoute(){
            this.$router.go(-1)            
        }
    },
    async created(){
        await this.$store.dispatch('GET_FARMREPORTS');
        this.loading = false;
    },
};
</script>
